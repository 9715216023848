import React from "react"
import { connect } from "react-redux"

import Layout from "../../components/layout"
import '../../../node_modules/react-vis/dist/style.css';
import ResultsOverview from "../../components/results/resultsOverview"
import BudgetGraph from "../../components/results/budgetGraph"
import PaymentTable from "../../components/debtPlan/paymentTable"
import {isAuthenticated, login} from "../../utils/auth"
var FA = require('react-fontawesome')

const ResultsPage = ({debts, budget, dispatch}) => {
  if (!isAuthenticated()) {
    login()
    return <p>Redirecting to login...</p>
  }
  
  return (
  <Layout>
    <h2>YOUR PLAN</h2>
    <p>Congratulations! You’ve created your own debt plan ⚡</p>
    <p>At the moment we don’t have a function to save your plan but you can save it as a PDF (File -> Print -> Destination -> Save as PDF)</p>
    <h4>What next?</h4>
    <h5>Automate your finances </h5>
    <p>Setting a budget is one thing, sticking to it is another. The best way to stay on track is
to minimise the mental effort and stress involved in managing your cash flow. Take your target % goals budget. Set up a standing order/direct debit (you can do this via your banking app or on the phone) which transfers that % of your post-tax income, into the appropriate account/towards your debt, every month.</p>
<p>You're then left with your wants budget and your needs budget. If you want to keep themseparate and track your individual purchases to ensure you're sticking to your %s, you might find it easier to have two separate accounts (or 'pots' if your bank provides these).For instance, one account out of which your needs (rent, utilities, phone bill etc.) are paid for and another account for 'wants' (eating out, Netflix etc.). Alternatively, if you'd rather not track purchases on a wants and needs basis, you can simply have one account for both wants and needs and review your spending monthly </p>
<h5>Be financially inspired!</h5>
<p>Go Fund Yourself is also an Instagram page, book and online platform, run by me, Alice :)Find me over here:
<ul>
<li><a href="https://www.instagram.com/go_fund_yourself_/?hl=en"><FA name="instagram" /></a></li>
<li> <a href="www.gofundyourself.co">www.gofundyourself.co</a></li>
</ul>
</p>
    <div className="card text-dark bg-light w-100 mt-5 mb-5">
        <div className="card-header text-center">Your Debt & Budget Plan</div>
        <div className="card-body">
          <div className="card-deck mt-5">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Your Overview</h5>
                <ResultsOverview></ResultsOverview>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Your Budget</h5>
                <div className="pb-5 overflow-auto">
                  <BudgetGraph></BudgetGraph>
                </div>
              </div>
            </div>
          </div>
          <div className="card-text mt-5">
            <div className="card">
              <div className="card-body overflow-auto">
              <PaymentTable></PaymentTable>
              </div>
            </div>
          </div>
        </div>
    </div>

  </Layout>
  )
}

export default connect(state => ({ debts: state.debt.debts, budget: state.budget.budget }))(ResultsPage)
